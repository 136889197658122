/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mix-dev@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InterludeInput } from '../models';
// @ts-ignore
import { InterludeListOutput } from '../models';
// @ts-ignore
import { InterludeOutput } from '../models';
// @ts-ignore
import { InterludeSearchInput } from '../models';
// @ts-ignore
import { SimpleError } from '../models';
/**
 * InterludeApi - axios parameter creator
 * @export
 */
export const InterludeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new interlude to the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {InterludeInput} interludeInput Interlude definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInterlude: async (stage: 'LIVE' | 'SANDBOX', interludeInput: InterludeInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('addInterlude', 'stage', stage)
            // verify required parameter 'interludeInput' is not null or undefined
            assertParamExists('addInterlude', 'interludeInput', interludeInput)
            const localVarPath = `/interludes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interludeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing interlude in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterlude: async (stage: 'LIVE' | 'SANDBOX', interludeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('deleteInterlude', 'stage', stage)
            // verify required parameter 'interludeId' is not null or undefined
            assertParamExists('deleteInterlude', 'interludeId', interludeId)
            const localVarPath = `/interludes/{interludeId}`
                .replace(`{${"interludeId"}}`, encodeURIComponent(String(interludeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve an interlude from the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterlude: async (stage: 'LIVE' | 'SANDBOX', interludeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getInterlude', 'stage', stage)
            // verify required parameter 'interludeId' is not null or undefined
            assertParamExists('getInterlude', 'interludeId', interludeId)
            const localVarPath = `/interludes/{interludeId}`
                .replace(`{${"interludeId"}}`, encodeURIComponent(String(interludeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enumerates interludes in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInterludes: async (stage: 'LIVE' | 'SANDBOX', maxResults?: number, nextToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('listInterludes', 'stage', stage)
            const localVarPath = `/interludes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches interludes in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {InterludeSearchInput} interludeSearchInput Search criteria
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInterludes: async (stage: 'LIVE' | 'SANDBOX', interludeSearchInput: InterludeSearchInput, maxResults?: number, nextToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('searchInterludes', 'stage', stage)
            // verify required parameter 'interludeSearchInput' is not null or undefined
            assertParamExists('searchInterludes', 'interludeSearchInput', interludeSearchInput)
            const localVarPath = `/interludes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interludeSearchInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing interlude in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {InterludeInput} interludeInput Interlude definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterlude: async (stage: 'LIVE' | 'SANDBOX', interludeId: string, interludeInput: InterludeInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('updateInterlude', 'stage', stage)
            // verify required parameter 'interludeId' is not null or undefined
            assertParamExists('updateInterlude', 'interludeId', interludeId)
            // verify required parameter 'interludeInput' is not null or undefined
            assertParamExists('updateInterlude', 'interludeInput', interludeInput)
            const localVarPath = `/interludes/{interludeId}`
                .replace(`{${"interludeId"}}`, encodeURIComponent(String(interludeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interludeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterludeApi - functional programming interface
 * @export
 */
export const InterludeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterludeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new interlude to the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {InterludeInput} interludeInput Interlude definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInterlude(stage: 'LIVE' | 'SANDBOX', interludeInput: InterludeInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterludeOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInterlude(stage, interludeInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing interlude in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInterlude(stage: 'LIVE' | 'SANDBOX', interludeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInterlude(stage, interludeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve an interlude from the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterlude(stage: 'LIVE' | 'SANDBOX', interludeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterludeOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterlude(stage, interludeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enumerates interludes in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInterludes(stage: 'LIVE' | 'SANDBOX', maxResults?: number, nextToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterludeListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInterludes(stage, maxResults, nextToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches interludes in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {InterludeSearchInput} interludeSearchInput Search criteria
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInterludes(stage: 'LIVE' | 'SANDBOX', interludeSearchInput: InterludeSearchInput, maxResults?: number, nextToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterludeListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInterludes(stage, interludeSearchInput, maxResults, nextToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing interlude in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {InterludeInput} interludeInput Interlude definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInterlude(stage: 'LIVE' | 'SANDBOX', interludeId: string, interludeInput: InterludeInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterludeOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInterlude(stage, interludeId, interludeInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InterludeApi - factory interface
 * @export
 */
export const InterludeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterludeApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new interlude to the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {InterludeInput} interludeInput Interlude definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInterlude(stage: 'LIVE' | 'SANDBOX', interludeInput: InterludeInput, options?: any): AxiosPromise<InterludeOutput> {
            return localVarFp.addInterlude(stage, interludeInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing interlude in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterlude(stage: 'LIVE' | 'SANDBOX', interludeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInterlude(stage, interludeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve an interlude from the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterlude(stage: 'LIVE' | 'SANDBOX', interludeId: string, options?: any): AxiosPromise<InterludeOutput> {
            return localVarFp.getInterlude(stage, interludeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enumerates interludes in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInterludes(stage: 'LIVE' | 'SANDBOX', maxResults?: number, nextToken?: string, options?: any): AxiosPromise<InterludeListOutput> {
            return localVarFp.listInterludes(stage, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches interludes in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {InterludeSearchInput} interludeSearchInput Search criteria
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInterludes(stage: 'LIVE' | 'SANDBOX', interludeSearchInput: InterludeSearchInput, maxResults?: number, nextToken?: string, options?: any): AxiosPromise<InterludeListOutput> {
            return localVarFp.searchInterludes(stage, interludeSearchInput, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing interlude in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} interludeId The ID of the interlude to update or delete
         * @param {InterludeInput} interludeInput Interlude definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterlude(stage: 'LIVE' | 'SANDBOX', interludeId: string, interludeInput: InterludeInput, options?: any): AxiosPromise<InterludeOutput> {
            return localVarFp.updateInterlude(stage, interludeId, interludeInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterludeApi - object-oriented interface
 * @export
 * @class InterludeApi
 * @extends {BaseAPI}
 */
export class InterludeApi extends BaseAPI {
    /**
     * 
     * @summary Add a new interlude to the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {InterludeInput} interludeInput Interlude definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterludeApi
     */
    public addInterlude(stage: 'LIVE' | 'SANDBOX', interludeInput: InterludeInput, options?: AxiosRequestConfig) {
        return InterludeApiFp(this.configuration).addInterlude(stage, interludeInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing interlude in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {string} interludeId The ID of the interlude to update or delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterludeApi
     */
    public deleteInterlude(stage: 'LIVE' | 'SANDBOX', interludeId: string, options?: AxiosRequestConfig) {
        return InterludeApiFp(this.configuration).deleteInterlude(stage, interludeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve an interlude from the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {string} interludeId The ID of the interlude to update or delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterludeApi
     */
    public getInterlude(stage: 'LIVE' | 'SANDBOX', interludeId: string, options?: AxiosRequestConfig) {
        return InterludeApiFp(this.configuration).getInterlude(stage, interludeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enumerates interludes in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {number} [maxResults] The maximum number of results to return for paginated queries
     * @param {string} [nextToken] The next page token to retrieve from a paginated query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterludeApi
     */
    public listInterludes(stage: 'LIVE' | 'SANDBOX', maxResults?: number, nextToken?: string, options?: AxiosRequestConfig) {
        return InterludeApiFp(this.configuration).listInterludes(stage, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches interludes in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {InterludeSearchInput} interludeSearchInput Search criteria
     * @param {number} [maxResults] The maximum number of results to return for paginated queries
     * @param {string} [nextToken] The next page token to retrieve from a paginated query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterludeApi
     */
    public searchInterludes(stage: 'LIVE' | 'SANDBOX', interludeSearchInput: InterludeSearchInput, maxResults?: number, nextToken?: string, options?: AxiosRequestConfig) {
        return InterludeApiFp(this.configuration).searchInterludes(stage, interludeSearchInput, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing interlude in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {string} interludeId The ID of the interlude to update or delete
     * @param {InterludeInput} interludeInput Interlude definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterludeApi
     */
    public updateInterlude(stage: 'LIVE' | 'SANDBOX', interludeId: string, interludeInput: InterludeInput, options?: AxiosRequestConfig) {
        return InterludeApiFp(this.configuration).updateInterlude(stage, interludeId, interludeInput, options).then((request) => request(this.axios, this.basePath));
    }
}
