/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mix-dev@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { QcarInput } from '../models';
// @ts-ignore
import { QcarListOutput } from '../models';
// @ts-ignore
import { QcarOutput } from '../models';
// @ts-ignore
import { QcarSearchInput } from '../models';
// @ts-ignore
import { SimpleError } from '../models';
/**
 * QcarApi - axios parameter creator
 * @export
 */
export const QcarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new qcar to the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {QcarInput} qcarInput Qcar definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addQcar: async (stage: 'LIVE' | 'SANDBOX', qcarInput: QcarInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('addQcar', 'stage', stage)
            // verify required parameter 'qcarInput' is not null or undefined
            assertParamExists('addQcar', 'qcarInput', qcarInput)
            const localVarPath = `/qcars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(qcarInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an qcar in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} qcarId The id of the qcar to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQcar: async (stage: 'LIVE' | 'SANDBOX', qcarId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('deleteQcar', 'stage', stage)
            // verify required parameter 'qcarId' is not null or undefined
            assertParamExists('deleteQcar', 'qcarId', qcarId)
            const localVarPath = `/qcars/{qcarId}`
                .replace(`{${"qcarId"}}`, encodeURIComponent(String(qcarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve an qcar from the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} qcarId The id of the qcar to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQcar: async (stage: 'LIVE' | 'SANDBOX', qcarId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getQcar', 'stage', stage)
            // verify required parameter 'qcarId' is not null or undefined
            assertParamExists('getQcar', 'qcarId', qcarId)
            const localVarPath = `/qcars/{qcarId}`
                .replace(`{${"qcarId"}}`, encodeURIComponent(String(qcarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches qcars in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {QcarSearchInput} qcarSearchInput Search criteria
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchQcars: async (stage: 'LIVE' | 'SANDBOX', qcarSearchInput: QcarSearchInput, maxResults?: number, nextToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('searchQcars', 'stage', stage)
            // verify required parameter 'qcarSearchInput' is not null or undefined
            assertParamExists('searchQcars', 'qcarSearchInput', qcarSearchInput)
            const localVarPath = `/qcars/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(qcarSearchInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing qcar in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} qcarId The id of the qcar to update or delete
         * @param {QcarInput} qcarInput Qcar definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQcar: async (stage: 'LIVE' | 'SANDBOX', qcarId: string, qcarInput: QcarInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('updateQcar', 'stage', stage)
            // verify required parameter 'qcarId' is not null or undefined
            assertParamExists('updateQcar', 'qcarId', qcarId)
            // verify required parameter 'qcarInput' is not null or undefined
            assertParamExists('updateQcar', 'qcarInput', qcarInput)
            const localVarPath = `/qcars/{qcarId}`
                .replace(`{${"qcarId"}}`, encodeURIComponent(String(qcarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(qcarInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QcarApi - functional programming interface
 * @export
 */
export const QcarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QcarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new qcar to the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {QcarInput} qcarInput Qcar definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addQcar(stage: 'LIVE' | 'SANDBOX', qcarInput: QcarInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QcarOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addQcar(stage, qcarInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an qcar in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} qcarId The id of the qcar to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQcar(stage: 'LIVE' | 'SANDBOX', qcarId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQcar(stage, qcarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve an qcar from the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} qcarId The id of the qcar to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQcar(stage: 'LIVE' | 'SANDBOX', qcarId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QcarOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQcar(stage, qcarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches qcars in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {QcarSearchInput} qcarSearchInput Search criteria
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchQcars(stage: 'LIVE' | 'SANDBOX', qcarSearchInput: QcarSearchInput, maxResults?: number, nextToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QcarListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchQcars(stage, qcarSearchInput, maxResults, nextToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing qcar in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} qcarId The id of the qcar to update or delete
         * @param {QcarInput} qcarInput Qcar definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQcar(stage: 'LIVE' | 'SANDBOX', qcarId: string, qcarInput: QcarInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QcarOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQcar(stage, qcarId, qcarInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QcarApi - factory interface
 * @export
 */
export const QcarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QcarApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new qcar to the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {QcarInput} qcarInput Qcar definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addQcar(stage: 'LIVE' | 'SANDBOX', qcarInput: QcarInput, options?: any): AxiosPromise<QcarOutput> {
            return localVarFp.addQcar(stage, qcarInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an qcar in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} qcarId The id of the qcar to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQcar(stage: 'LIVE' | 'SANDBOX', qcarId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteQcar(stage, qcarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve an qcar from the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} qcarId The id of the qcar to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQcar(stage: 'LIVE' | 'SANDBOX', qcarId: string, options?: any): AxiosPromise<QcarOutput> {
            return localVarFp.getQcar(stage, qcarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches qcars in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {QcarSearchInput} qcarSearchInput Search criteria
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchQcars(stage: 'LIVE' | 'SANDBOX', qcarSearchInput: QcarSearchInput, maxResults?: number, nextToken?: string, options?: any): AxiosPromise<QcarListOutput> {
            return localVarFp.searchQcars(stage, qcarSearchInput, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing qcar in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} qcarId The id of the qcar to update or delete
         * @param {QcarInput} qcarInput Qcar definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQcar(stage: 'LIVE' | 'SANDBOX', qcarId: string, qcarInput: QcarInput, options?: any): AxiosPromise<QcarOutput> {
            return localVarFp.updateQcar(stage, qcarId, qcarInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QcarApi - object-oriented interface
 * @export
 * @class QcarApi
 * @extends {BaseAPI}
 */
export class QcarApi extends BaseAPI {
    /**
     * 
     * @summary Add a new qcar to the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {QcarInput} qcarInput Qcar definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QcarApi
     */
    public addQcar(stage: 'LIVE' | 'SANDBOX', qcarInput: QcarInput, options?: AxiosRequestConfig) {
        return QcarApiFp(this.configuration).addQcar(stage, qcarInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an qcar in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {string} qcarId The id of the qcar to update or delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QcarApi
     */
    public deleteQcar(stage: 'LIVE' | 'SANDBOX', qcarId: string, options?: AxiosRequestConfig) {
        return QcarApiFp(this.configuration).deleteQcar(stage, qcarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve an qcar from the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {string} qcarId The id of the qcar to update or delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QcarApi
     */
    public getQcar(stage: 'LIVE' | 'SANDBOX', qcarId: string, options?: AxiosRequestConfig) {
        return QcarApiFp(this.configuration).getQcar(stage, qcarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches qcars in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {QcarSearchInput} qcarSearchInput Search criteria
     * @param {number} [maxResults] The maximum number of results to return for paginated queries
     * @param {string} [nextToken] The next page token to retrieve from a paginated query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QcarApi
     */
    public searchQcars(stage: 'LIVE' | 'SANDBOX', qcarSearchInput: QcarSearchInput, maxResults?: number, nextToken?: string, options?: AxiosRequestConfig) {
        return QcarApiFp(this.configuration).searchQcars(stage, qcarSearchInput, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing qcar in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {string} qcarId The id of the qcar to update or delete
     * @param {QcarInput} qcarInput Qcar definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QcarApi
     */
    public updateQcar(stage: 'LIVE' | 'SANDBOX', qcarId: string, qcarInput: QcarInput, options?: AxiosRequestConfig) {
        return QcarApiFp(this.configuration).updateQcar(stage, qcarId, qcarInput, options).then((request) => request(this.axios, this.basePath));
    }
}
