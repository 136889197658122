/* tslint:disable */
/* eslint-disable */
/**
 * Mousai Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mix-dev@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Lifecycle state of a music experience that can be used in search API query.
 * @export
 * @enum {string}
 */

export enum ExperienceStateFilter {
    DRAFT = 'DRAFT',
    REVIEW = 'REVIEW',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    LIVE = 'LIVE',
    ARCHIVED = 'ARCHIVED'
}


