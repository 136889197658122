import {
    AssetApi,
    AssociationsApi,
    Configuration,
    InterludeApi,
    QcarApi,
    StageApi,
} from '../gen/generated-client';

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { aws4Interceptor, Credentials, InterceptorOptions as Aws4Options } from 'aws4-axios';

import * as endpoints from '../config/endpoints.json';
export const serviceEndpoints = endpoints;

export * from '../gen/generated-client/api';
export * from '../gen/generated-client/models';

export type { InterceptorOptions as Aws4Options, Credentials } from 'aws4-axios';

export interface CatalogManagerClientConfig {
    /**
     * The base URL of the Catalog Manager Service to use. If left empty,
     * the client will attempt to use process.env.DOMAIN to determine the default value.
     */
    serviceBaseUrl?: string;
    /** 
     * The role ARN to assume when sending a request to the service. 
     * Takes precedence over the settings in {@link aws4Options}.
     */
    requestRoleArn?: string;
    /**
     * Credentials to be used to sign requests. If left empty,
     * the environment will be checked for credentials.
     */
    credentials?: Credentials;
    /**
     * Options for the AWS4 signing interceptor. If left empty,
     * the target service and region will be inferred from the request
     * (when possible)
     */
    aws4Options?: Aws4Options;
    /**
     * axios configuration to override default
     */
    axiosConfig?: AxiosRequestConfig;
    /**
     * Optional configuration to add aws V4 signature to requests.
     * @default true
     */
    addAwsV4SigningToRequests?: boolean;
}

export class CatalogManagerClient {
    public readonly axiosInstance: AxiosInstance;
    private readonly serviceBaseUrl: string | undefined;

    /**
     * Constructs default clients for all APIs supported by Catalog Manager
     * By default the clients will:
     * * Infer the base URL and assume role ARN based on the DOMAIN env. variable (beta|gamma|prod)
     * * Sign the requests using AWS4 using credentials fetched from the standard AWS env. variables
     * @param config Configuration overrides
     */
    constructor(config: CatalogManagerClientConfig) {
        const endpoint = getDefaultEndpoint();

        this.serviceBaseUrl = config.serviceBaseUrl ?? endpoint?.baseUrl;
        if (!this.serviceBaseUrl) {
            throw new Error('Could not determine base URL for the service');
        }

        // Create our own axios instance to add request signing to.
        this.axiosInstance = axios.create(config.axiosConfig);

        if (config.addAwsV4SigningToRequests !== false) {
            const aws4Options = config.aws4Options ?? {
                region: 'us-east-1',
                service: 'execute-api',
                assumeRoleArn: config.requestRoleArn ?? endpoint?.roleArn,
            };
            const interceptor = aws4Interceptor(aws4Options, config.credentials);
            this.axiosInstance.interceptors.request.use(interceptor);
        }
    }

    public get interludes() {
        return new InterludeApi(new Configuration(), this.serviceBaseUrl, this.axiosInstance);
    }

    public get assets() {
        return new AssetApi(new Configuration(), this.serviceBaseUrl, this.axiosInstance);
    }

    public get associations() {
        return new AssociationsApi(new Configuration(), this.serviceBaseUrl, this.axiosInstance);
    }

    public get stages() {
        return new StageApi(new Configuration(), this.serviceBaseUrl, this.axiosInstance);
    }

    public get qcars() {
        return new QcarApi(new Configuration(), this.serviceBaseUrl, this.axiosInstance);
    }
}

function isSupportedDomain(domain: string): domain is keyof typeof endpoints {
    return Object.getOwnPropertyNames(endpoints).includes(domain);
}

function getDefaultEndpoint(): { baseUrl: string; roleArn: string } | void {
    const domain = process.env['DOMAIN']?.toLowerCase();
    if (domain && isSupportedDomain(domain)) {
        return endpoints[domain];
    }
}
