/* tslint:disable */
/* eslint-disable */
/**
 * Mousai Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mix-dev@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateExperienceInput } from '../models';
// @ts-ignore
import { CreateExperienceOutput } from '../models';
// @ts-ignore
import { ExperienceSearchInput } from '../models';
// @ts-ignore
import { ExperienceSearchOutput } from '../models';
// @ts-ignore
import { GetExperienceOutput } from '../models';
// @ts-ignore
import { MousaiServiceException } from '../models';
// @ts-ignore
import { UpdateExperienceAssetsInput } from '../models';
// @ts-ignore
import { UpdateExperienceMetadataInput } from '../models';
// @ts-ignore
import { UpdateExperienceStateInput } from '../models';
/**
 * MusicExperienceApi - axios parameter creator
 * @export
 */
export const MusicExperienceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new music experience.
         * @param {CreateExperienceInput} createExperienceInput Logical music experience.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExperience: async (createExperienceInput: CreateExperienceInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExperienceInput' is not null or undefined
            assertParamExists('createExperience', 'createExperienceInput', createExperienceInput)
            const localVarPath = `/music-experiences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExperienceInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch details for a specific music experience.
         * @param {string} experienceId Unique ID of a music experience.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperience: async (experienceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experienceId' is not null or undefined
            assertParamExists('getExperience', 'experienceId', experienceId)
            const localVarPath = `/music-experiences/{experienceId}`
                .replace(`{${"experienceId"}}`, encodeURIComponent(String(experienceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for music experiences.
         * @param {ExperienceSearchInput} experienceSearchInput Music experience search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchExperiences: async (experienceSearchInput: ExperienceSearchInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experienceSearchInput' is not null or undefined
            assertParamExists('searchExperiences', 'experienceSearchInput', experienceSearchInput)
            const localVarPath = `/music-experiences/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(experienceSearchInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a music experience\'s assets (e.g. images, audio, etc.).
         * @param {string} experienceId Unique ID of a music experience.
         * @param {UpdateExperienceAssetsInput} updateExperienceAssetsInput Data to represent adding (including replacing) and/or removing assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperienceAssets: async (experienceId: string, updateExperienceAssetsInput: UpdateExperienceAssetsInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experienceId' is not null or undefined
            assertParamExists('updateExperienceAssets', 'experienceId', experienceId)
            // verify required parameter 'updateExperienceAssetsInput' is not null or undefined
            assertParamExists('updateExperienceAssets', 'updateExperienceAssetsInput', updateExperienceAssetsInput)
            const localVarPath = `/music-experiences/{experienceId}/update-assets`
                .replace(`{${"experienceId"}}`, encodeURIComponent(String(experienceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExperienceAssetsInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a music experience\'s metadata (e.g. title, start date, expiration date, etc.).
         * @param {string} experienceId Unique ID of a music experience.
         * @param {UpdateExperienceMetadataInput} updateExperienceMetadataInput Music experience metadata.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperienceMetadata: async (experienceId: string, updateExperienceMetadataInput: UpdateExperienceMetadataInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experienceId' is not null or undefined
            assertParamExists('updateExperienceMetadata', 'experienceId', experienceId)
            // verify required parameter 'updateExperienceMetadataInput' is not null or undefined
            assertParamExists('updateExperienceMetadata', 'updateExperienceMetadataInput', updateExperienceMetadataInput)
            const localVarPath = `/music-experiences/{experienceId}/update-metadata`
                .replace(`{${"experienceId"}}`, encodeURIComponent(String(experienceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExperienceMetadataInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a music experience\'s state (e.g. publish, archive, delete, etc.).
         * @param {string} experienceId Unique ID of a music experience.
         * @param {UpdateExperienceStateInput} updateExperienceStateInput Music experience state.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperienceState: async (experienceId: string, updateExperienceStateInput: UpdateExperienceStateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'experienceId' is not null or undefined
            assertParamExists('updateExperienceState', 'experienceId', experienceId)
            // verify required parameter 'updateExperienceStateInput' is not null or undefined
            assertParamExists('updateExperienceState', 'updateExperienceStateInput', updateExperienceStateInput)
            const localVarPath = `/music-experiences/{experienceId}/update-state`
                .replace(`{${"experienceId"}}`, encodeURIComponent(String(experienceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExperienceStateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MusicExperienceApi - functional programming interface
 * @export
 */
export const MusicExperienceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MusicExperienceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new music experience.
         * @param {CreateExperienceInput} createExperienceInput Logical music experience.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExperience(createExperienceInput: CreateExperienceInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateExperienceOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExperience(createExperienceInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch details for a specific music experience.
         * @param {string} experienceId Unique ID of a music experience.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperience(experienceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExperienceOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperience(experienceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for music experiences.
         * @param {ExperienceSearchInput} experienceSearchInput Music experience search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchExperiences(experienceSearchInput: ExperienceSearchInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperienceSearchOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchExperiences(experienceSearchInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a music experience\'s assets (e.g. images, audio, etc.).
         * @param {string} experienceId Unique ID of a music experience.
         * @param {UpdateExperienceAssetsInput} updateExperienceAssetsInput Data to represent adding (including replacing) and/or removing assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExperienceAssets(experienceId: string, updateExperienceAssetsInput: UpdateExperienceAssetsInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExperienceAssets(experienceId, updateExperienceAssetsInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a music experience\'s metadata (e.g. title, start date, expiration date, etc.).
         * @param {string} experienceId Unique ID of a music experience.
         * @param {UpdateExperienceMetadataInput} updateExperienceMetadataInput Music experience metadata.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExperienceMetadata(experienceId: string, updateExperienceMetadataInput: UpdateExperienceMetadataInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExperienceMetadata(experienceId, updateExperienceMetadataInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a music experience\'s state (e.g. publish, archive, delete, etc.).
         * @param {string} experienceId Unique ID of a music experience.
         * @param {UpdateExperienceStateInput} updateExperienceStateInput Music experience state.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExperienceState(experienceId: string, updateExperienceStateInput: UpdateExperienceStateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExperienceState(experienceId, updateExperienceStateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MusicExperienceApi - factory interface
 * @export
 */
export const MusicExperienceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MusicExperienceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new music experience.
         * @param {CreateExperienceInput} createExperienceInput Logical music experience.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExperience(createExperienceInput: CreateExperienceInput, options?: any): AxiosPromise<CreateExperienceOutput> {
            return localVarFp.createExperience(createExperienceInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch details for a specific music experience.
         * @param {string} experienceId Unique ID of a music experience.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperience(experienceId: string, options?: any): AxiosPromise<GetExperienceOutput> {
            return localVarFp.getExperience(experienceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for music experiences.
         * @param {ExperienceSearchInput} experienceSearchInput Music experience search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchExperiences(experienceSearchInput: ExperienceSearchInput, options?: any): AxiosPromise<ExperienceSearchOutput> {
            return localVarFp.searchExperiences(experienceSearchInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a music experience\'s assets (e.g. images, audio, etc.).
         * @param {string} experienceId Unique ID of a music experience.
         * @param {UpdateExperienceAssetsInput} updateExperienceAssetsInput Data to represent adding (including replacing) and/or removing assets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperienceAssets(experienceId: string, updateExperienceAssetsInput: UpdateExperienceAssetsInput, options?: any): AxiosPromise<void> {
            return localVarFp.updateExperienceAssets(experienceId, updateExperienceAssetsInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a music experience\'s metadata (e.g. title, start date, expiration date, etc.).
         * @param {string} experienceId Unique ID of a music experience.
         * @param {UpdateExperienceMetadataInput} updateExperienceMetadataInput Music experience metadata.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperienceMetadata(experienceId: string, updateExperienceMetadataInput: UpdateExperienceMetadataInput, options?: any): AxiosPromise<void> {
            return localVarFp.updateExperienceMetadata(experienceId, updateExperienceMetadataInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a music experience\'s state (e.g. publish, archive, delete, etc.).
         * @param {string} experienceId Unique ID of a music experience.
         * @param {UpdateExperienceStateInput} updateExperienceStateInput Music experience state.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperienceState(experienceId: string, updateExperienceStateInput: UpdateExperienceStateInput, options?: any): AxiosPromise<void> {
            return localVarFp.updateExperienceState(experienceId, updateExperienceStateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MusicExperienceApi - object-oriented interface
 * @export
 * @class MusicExperienceApi
 * @extends {BaseAPI}
 */
export class MusicExperienceApi extends BaseAPI {
    /**
     * 
     * @summary Create new music experience.
     * @param {CreateExperienceInput} createExperienceInput Logical music experience.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicExperienceApi
     */
    public createExperience(createExperienceInput: CreateExperienceInput, options?: AxiosRequestConfig) {
        return MusicExperienceApiFp(this.configuration).createExperience(createExperienceInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch details for a specific music experience.
     * @param {string} experienceId Unique ID of a music experience.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicExperienceApi
     */
    public getExperience(experienceId: string, options?: AxiosRequestConfig) {
        return MusicExperienceApiFp(this.configuration).getExperience(experienceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for music experiences.
     * @param {ExperienceSearchInput} experienceSearchInput Music experience search query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicExperienceApi
     */
    public searchExperiences(experienceSearchInput: ExperienceSearchInput, options?: AxiosRequestConfig) {
        return MusicExperienceApiFp(this.configuration).searchExperiences(experienceSearchInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a music experience\'s assets (e.g. images, audio, etc.).
     * @param {string} experienceId Unique ID of a music experience.
     * @param {UpdateExperienceAssetsInput} updateExperienceAssetsInput Data to represent adding (including replacing) and/or removing assets.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicExperienceApi
     */
    public updateExperienceAssets(experienceId: string, updateExperienceAssetsInput: UpdateExperienceAssetsInput, options?: AxiosRequestConfig) {
        return MusicExperienceApiFp(this.configuration).updateExperienceAssets(experienceId, updateExperienceAssetsInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a music experience\'s metadata (e.g. title, start date, expiration date, etc.).
     * @param {string} experienceId Unique ID of a music experience.
     * @param {UpdateExperienceMetadataInput} updateExperienceMetadataInput Music experience metadata.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicExperienceApi
     */
    public updateExperienceMetadata(experienceId: string, updateExperienceMetadataInput: UpdateExperienceMetadataInput, options?: AxiosRequestConfig) {
        return MusicExperienceApiFp(this.configuration).updateExperienceMetadata(experienceId, updateExperienceMetadataInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a music experience\'s state (e.g. publish, archive, delete, etc.).
     * @param {string} experienceId Unique ID of a music experience.
     * @param {UpdateExperienceStateInput} updateExperienceStateInput Music experience state.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicExperienceApi
     */
    public updateExperienceState(experienceId: string, updateExperienceStateInput: UpdateExperienceStateInput, options?: AxiosRequestConfig) {
        return MusicExperienceApiFp(this.configuration).updateExperienceState(experienceId, updateExperienceStateInput, options).then((request) => request(this.axios, this.basePath));
    }
}
